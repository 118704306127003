.root {
  flex-direction: column !important;
  align-items: center !important;
  text-align: center;
  padding: 0 16px;
}
.icon {
  margin-top: 176px;
  margin-bottom: 32px;
  width: 82px;
  height: 82px;
}
.description {
  margin-top: 12px;
  margin-bottom: 48px;
}
.submit {
  width: auto;
  margin-bottom: 100px;
}
@media screen and (max-width: 768px) {
  .description {
    margin-top: 8px;
    margin-bottom: 32px;
  }
  .icon {
    margin-top: 141px;
    width: 57px;
    height: 57px;
  }
  .submit {
    width: 100%;
    margin-bottom: 100px;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainCenter {
  display: flex;
  justify-content: center;
}

.centerBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.root {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5000;
}

.logoYugo {
  width: 48px;
  height: 48px;
}
.ureLogo {
  width: 130px;
}
.welcomeIcon {
  width: 40px;
  height: 40px;
}

.header {
  height: 70px;
  background-color: var(--color-white);
  box-shadow: 0 2px 8px 0 rgba(51, 53, 50, 0.1);

  &__content {
    width: 1128px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  :global(body.coliving) & {
    background-color: var(--color-gray_1);
  }
}
.navbarItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: var(--color-yellow);
}
.modalOverlayStyles {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: 10000000;
}

.modalStyles {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100% - 40px);
  background: var(--color-white);
  overflow: auto;
  z-index: 10000000;
  outline: none;
}

.MenuModalContentItemStyles {
  display: block;
  min-height: 56px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #d9d5dc;
}
.WelcomeTippyContentItemStyles {
  display: block;
  position: relative;
  padding: 16px 72px;
  cursor: pointer;
  :hover {
    background-color: var(--color-background);
    ::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      content: '';
      width: 5px;
      background-color: var(--color-yellow_6);
    }
  }
}

@media screen and (max-width: 768px) {
  .root {
    position: fixed;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.1);
  }
  .logoYugo {
    width: 36px;
    height: 36px;
  }
  .header {
    height: 48px;
    padding: 0 0 0 16px;
    box-shadow: 0 2px 8px 0 rgba(178, 171, 185, 0.03);
    &__content {
      width: 100%;
      margin: 0;
    }
  }
}

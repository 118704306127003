.footer {
  background-color: var(--color-purple_6);
  :global(body.coliving) &,
  :global(body.ure) & {
    background-color: var(--color—footer-bg);
  }
}

.convenience {
  padding-top: 16px;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 40px;
    height: 6px;
    background-color: var(--color-green_6);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
  }
}
.copyRight {
  box-shadow: 0px 1px 0px 0px #645673 inset;
}
.copyRightWrapper {
  width: 1128px;
  margin: 0 auto;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container {
  width: 1128px;
  margin: 0 auto;
  padding-top: 28px;
  padding-bottom: 40px;
  display: flex;
  align-items: top;
}
.location {
  margin-right: 55px;
  width: 185px;
  margin-bottom: 16px;
  &:nth-child(4n + 4) {
    margin-right: 0;
  }
}
.locations {
  flex: 1;
  padding-left: 100px;
}
.locationsWrapper {
  display: flex;
  flex-wrap: wrap;
}
.logos {
  width: 100px;
}
.languageSelector {
  padding-top: 16px;
}
.locationText {
  color: var(--color-white);
  :global(body.coliving) & {
    color: var(--color-purple_6);
  }
}
.convenienceText {
  color: var(--color-purple_1);
  :global(body.coliving) & {
    color: var(--color-purple_6);
  }
}
.copyRightTitle {
  color: var(--color-outline);
  :global(body.coliving) & {
    color: var(--color-purple_6);
  }
}
@media screen and (max-width: 768px) {
  .logos {
    width: 100%;
    text-align: center;
    padding-bottom: 36px;
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.15);
  }
  .footer {
    padding-bottom: 16px;
  }
  .convenience {
    padding: 16px 16px 20px;
    margin-top: 24px;
    &:before {
      left: 16px;
    }
  }
  .copyRightWrapper {
    width: 100%;
    height: 56px;
    padding: 0 16px;
  }

  .container {
    width: 100%;
    margin: 0;
    display: block;
    padding-bottom: 0;
  }
  .locations {
    padding-left: 0;
  }
}

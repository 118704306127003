.loginForm {
  display: flex;
  width: 650px;
  margin: 0 auto;
  flex-direction: column;
}
.loginInput {
  margin: 15px 0;
  width: 100%;
}
.loginSubmit {
  margin-top: 16px;
  margin-bottom: 20px;
  width: 100%;
}

@media screen and (max-width: 1280px) and (min-width: 1024px) {
  .loginForm {
    width: 600px;
  }
}
@media screen and (max-width: 1024px) {
  .loginForm {
    width: 100%;
  }

  .loginInput {
    margin: 8px 0;
  }

  .loginSubmit {
    margin-top: 32px;
  }
}

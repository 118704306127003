@import '~public/styles/flex';

.logo {
  @extend .mainCenter;
  width: 100%;
  align-items: baseline;
  position: absolute;
  left: 0;
  bottom: 37px;
}

.logoRegister {
  width: 130px;
  height: 80px;
  top: 52px;
  left: 52px;
}

.logoMobile {
  @extend .center;
  width: 100%;
  height: 56px;
  background: var(--color-greyFa);
}

.logoMobileFixed {
  position: fixed;
  left: 0;
  bottom: 0;
}

.container {
  display: flex;
  flex-direction: row;
}

.container_left {
  position: relative;
  width: 550px;
  min-height: 500px;
  height: 100vh;
  padding: 0 48px;
  background-size: cover;
  background-repeat: no-repeat;
}
.welcome {
  margin-top: 213px;
  margin-bottom: 16px;
}

.description {
  position: absolute;
  bottom: 132px;
  left: 48px;
  width: 454px;
  line-height: 1.78;
  margin: 0 auto;
  color: var(--color-white);
}

.container_right {
  width: calc(100% - 550px);
  height: 100vh;
  overflow: scroll;
  padding: 16px 16px 68px;
  position: relative;
}

.wrapper {
  margin-top: 48px;
  margin-right: 48px;
  text-align: right;
}
.wrapper_login {
  margin-bottom: 120px;
}
.wrapper_register {
  margin-bottom: 40px;
}
.title {
  width: 650px;
  margin: 0 auto 40px;
}
.auth_description {
  margin-top: 16px;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .container {
    display: block;
  }
  .container_left {
    display: none;
  }
  .container_right {
    width: 100%;
    height: auto;
  }
  .wrapper {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 46px !important;
  }
  .title {
    width: 100%;
  }
  .auth_description {
    margin-top: 4px;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 768px) {
  .container_left {
    width: 400px;
    min-height: 400px;
    padding: 0 28px;
  }
  .description {
    width: 300px;
  }
  .container_right {
    width: calc(100% - 400px);
  }
  .title {
    width: 600px;
  }
}

.locationText {
  color: var(--color-white) !important;
  :global(body.coliving) & {
    color: var(--color-purple_6) !important;
  }
}
.more {
  margin-left: 8px;
  transition: transform 0.3s ease-out;
  fill: var(--color-white);
  :global(body.coliving) & {
    fill: var(--color-purple_6);
  }
}

.moreOpen {
  transform: rotate(-180deg);
}

.name {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.root {
  position: relative;
  min-height: 100vh;
}
.content {
  padding-top: 0;
  background: var(--color-white);
  :global(body.coliving) & {
    background: var(--color-gray_1);
  }
}
.contentProcess {
  min-height: 700px;
  position: relative;
}
.contentFlexiable {
  flex: 1;
}
.layoutWrapper {
  background: var(--color-white);
  :global(body.coliving) & {
    background: var(--color-gray_1);
  }
}
.wrapper {
  position: relative;
  background: var(--color-white);
  z-index: 10;
  :global(body.coliving) & {
    background: var(--color-gray_1);
  }
}

.loading {
  position: absolute;
  top: 200px;
  left: 50%;
  margin-left: -400px;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .content {
    padding-top: 48px;
  }

  .contentProcess {
    min-height: 580px;
  }
  .loading {
    margin-left: -150px;
  }
}
